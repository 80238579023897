<template>
<!-- 云健身-云健身运动项目喜爱排名 -->
    <div class="baixing darkBack">
        <div class="baixingnav">
            <div>云健身运动项目喜爱排名</div>
        </div>
        <div id="jianoption1"></div>
    </div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";

export default {
    data () {
        return {
            sport:{
                data: ["深蹲", "深蹲纵跳", "开合跳", "合掌跳", "卷腹", "展臂大跳", "仰卧交替抬腿", "高抬腿", "侧支撑抬腿", "平板支撑", "仰卧起坐", "跳绳", "弯腰触地", "蛙跳"],
                number:["400", "380", "360", "340", "320", "300", "280", "260", "240", "220", "200", "180", "160", "140"],
                max: 13,
            },
        }
    },
    methods: {
        fitness(id, number){
            let that = this;
            let canye = this.$echarts.init(document.getElementById(id));
            // var data = number.number
            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    splitNumber: 6,
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: '#EBBB2F40',
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '6%',  
                    // right: '16%', 
                    top:'2%', 
                    bottom: '1%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    // max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: '#E58239',
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        // margin: 176,//刻度标签与轴线之间的距离
                        interval:0,//横轴信息全部显示 
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: "#7EA7E2",
                            // fontSize: '0.13rem',
                            fontSize: '0.13rem',
                            // align: 'left'
                        },                           
                    },
                },
                series: [{
                    // realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barMaxWidth:20,
                    barWidth : '40%',              // 柱子的宽度
                    data: number.number,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: "#E5813A" // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: "#EFDC29" // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: '#EFDC29',    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };

            canye.setOption(option);

            //图表自动缩放
            window.addEventListener("resize",function(){
            	canye.resize();
            });
        },
        // 获取数据
        getData(){
            let that = this;
            // this.fitness("jianoption1", this.sport)
            var data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }

            let info = JSON.parse(window.localStorage.getItem("screenUserInfo"));
            let url;
            if(info.is_city == 1) url = window.appHost+"/api/v1/screen/Cloud_Gym/sports_ranking"
            else url = window.appHost+"/api/v1/screen/Cloud_Gym/sports_ranking?area="+info.name
            // console.log(url)
            let result = window.ajaxRequest(url,JSON.stringify(data),'post')
            // console.log(result)
            if(result.code != 0){
                that.sport.data = [];
                that.sport.number = [];
                result.data.forEach((item,index)=>{
                    that.sport.data.push(item.title);
                    that.sport.number.push(item.num);
                })
                that.sport.max = result.data.length;
                that.fitness("jianoption1", that.sport)
            }
            
        }
    },
    mounted () {
        this.getData()
    }
}
</script>

<style scoped>
.baixing{
    width:5.97rem;
    height:6.78rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
#jianoption1{
    width:5.80rem;
    height:5.80rem;
    margin-top:0.2rem;
    /* border:1px solid red; */
    margin-left:-0.20rem;
}
</style>